import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image from '../../assets/img/Data.jpg'
import image1 from '../../assets/img/unnamed.png'
import image2 from '../../assets/img/unnamed-2.png'
import image3 from '../../assets/img/unnamed-3.png'
import { useMediaQuery } from 'react-responsive'

// core components

function Slide9(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 924px)' })
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   
      <div className="filter-white" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'black', fontWeight: 'bold' }}>
              OUR METHOD:
              </h2>
              <br/>
            </Col>
        
          </Row>
          <Row >
            <Col md={'5'}>
              <h5 style={{ color: 'black' }}>
              With a unique mix of consulting and field expertise, we provide a holistic approach to rapid, reliable and smooth supply chain systems. We harness data, analytics and design to give organizations a clear path to improve performance and reimagine how to continuously improve and outperform rivals.
<br/><br/>
We follow a structured method enhanced by capability building of both people and technology. Our process deploys cutting-edge techniques and offers a scalable approach that targets multi-year transformations.

              </h5>
           
            </Col>
            <Col md={'7'}>
          <Row>
       { isTabletOrMobile?null:  <Col md={'3'}>
          <div className="d-flex h-100 w-100 justify-content-center align-items-center" >
<img src={image1} style={{height:'80px',width:'100px'}}/>
</div>
              </Col>}
       
              <Col md={'9'} className="d-flex ">
    <Row>
    <h3 style={{ color: 'black', fontWeight: 'bold' }}>
            Clarity
              </h3>
              <h5 style={{ color: 'black' }}>
          We focus on determining what will have the biggest impact on an organization.

              </h5>
    </Row>
    
       
              </Col>
            
          </Row>
          <Row>
          { isTabletOrMobile?null:  <Col md={'3'}>
          <div className="d-flex h-100 w-100 justify-content-center align-items-center" >
<img src={image2} style={{height:'80px',width:'100px'}}/>
</div>
              </Col>}
       
              <Col md={'9'} className="d-flex ">
    <Row>
    <h3 style={{ color: 'black', fontWeight: 'bold' }}>
    Insights
              </h3>
              <h5 style={{ color: 'black' }}>
              We uncover robust new insights and then help our clients build capabilities to achieve continuous improvements.

              </h5>
    </Row>
    
       
              </Col>
            
          </Row>
          <Row>
          { isTabletOrMobile?null:  <Col md={'3'}>
          <div className="d-flex h-100 w-100 justify-content-center align-items-center" >
<img src={image3}  style={{height:'80px',width:'100px'}}/>
</div>
              </Col>
}
              <Col md={'9'} className="d-flex ">
    <Row>
    <h3 style={{ color: 'black', fontWeight: 'bold' }}>
    Human + Machine
              </h3>
              <h5 style={{ color: 'black' }}>
              What we can measure and analyze, we can understand and change. We strive for clear analysis and insights from our cutting-edge technology.
              </h5>
    </Row>
    
       
              </Col>
            
          </Row>
            
           
            </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide9;
