import React from "react";
import { createRef, useRef } from "react";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap/dist/css/bootstrap.css';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image from '../../assets/img/Wireless.JPG'
// core components

function Slide7(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   <div className="filter" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              OUR VALUES:

              </h2>
            </Col>
            <Col md={'7'}>
              <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              THE DYNAMICS OF A YOUNG START-UP WITH A WELL DESIGNED PROCESS ADAPTED TO SCALE  

 </h3>
              <br />
            </Col>
            <Col md={'6'}>
              <h5 style={{ color: 'white' }}>
              No matter what project we are working on, we feel like we are helping change the world. This ranges from reducing time to market, to predicting failures in machinery, to helping the environment. 
<br/>
<br/>
We are passionate, skilled, and professional driven by the need to create real and quantitative impact for our client’s business. 




              </h5>
           
            </Col>
          </Row>
          <br/>
          {/* <Button
                            // onClick={() => {
                            //   executeScrollSlide5()
                            //   // executeScroll()
                            // }}
                            //   href="#ContactUs"
                            className="btn-round "
                            color="success"
                            target="_blank"
                            size="medium"
                            outline
                            style={{fontSize:8,marginRight:'10px'}}
                          > Read More <ArrowForwardIcon fontSize={'small'}/></Button> */}
        </Container>
      </div>
    </>
  );
}

export default Slide7;
